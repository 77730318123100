import { Component, OnInit } from '@angular/core';
import { CartItem } from '../../models/cart-item';
import { ShippingDetails } from '../../models/api/shippingDetails';
import { CartService } from '../../services/cart.service';
import { Checkout } from '../../models/api/checkout';
import { Cart } from '../../models/api/cart';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { CartItem as ApiCartItem } from '../../models/api/cartItem';
import Swal from 'sweetalert2'
import { Pet } from '../../models/pets';
import { PetService } from '../../services/pets.service';
import { Item } from '../../models/item';
import { Contact } from '../../models/api/contact';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  isLoading = false;
  buttonMsg = "Apply for Adoption";
  pets: Pet[] = new Array();
  items: CartItem[] = new Array()
  pet: Pet = new Pet()
  chosen: string;
  item: Item = new Item();
  shipping: ShippingDetails = new ShippingDetails()
  paymentOptions = ["Apple Pay", "Cashapp", "Zelle"]
  contact: Contact = new Contact();
  constructor(private cartService: CartService, private apiService: ApiService, private petService: PetService) {
  }

  getPetName(id: string): string {
    return this.petService.find(id).name
  }

  ngOnInit() {
    this.pets = this.petService.findAll().filter(pet => !pet.adopted)
    this.cartService.getItems().subscribe((data: CartItem[]) => {
      this.items = data;
      if (this.items.length > 0) {
        this.pet = this.items[0].pet
      } else {
        this.pet = this.pets[0]
      }
      this.chosen = this.pet.id
    })
  }

  onChange(value: string) {
    console.log(value)
    let item: Item = new Item();
    item.pet = this.petService.find(value)
    this.cartService.add(item)
  }

  total() {
    return this.cartService.total();
  }

  grandTotal() {
    return this.cartService.grandTotal();
  }

  submit() {
    if(!this.isLoading) {
    this.buttonMsg = "Loading..."
    this.contact.subject = this.pet.name
    this.apiService.contact(this.contact)
    .subscribe((data: any) => {
      this.isLoading = true;
      Swal({
        title: 'Thanks!',
        text: 'Thank you! Your message has been received. We will contact you as soon as possible',
        type: 'success',
        confirmButtonText: 'Ok',
      })
      this.contact = new Contact();
      this.isLoading = false;
      this.buttonMsg = "Send Message";
    },
      (err: HttpErrorResponse) => {
        this.isLoading = false;
        Swal({
          title: 'Error!',
          text: 'Unable to send message. Make sure you are connected to the internet',
          type: 'warning',
          confirmButtonText: 'Ok got it!',
        })
        this.buttonMsg = "Send Message";
      });
    }
  }

  itemTotal(item: CartItem): number {
    let total: number = this.cartService.totalItems();
    return total;
  }

  getPrice(item: CartItem) {
    return this.cartService.getPrice(item.pet);
  }

  Shipping() {
    return this.cartService.shipping();
  }
}
