import { Deserializable } from "./deserializable";

export class Pet implements Deserializable {
    id: string;
    name: string;
    gender: string;
    age: string;
    neutered: string;
    vaccinated: string;
    size: string;
    description1: string;
    //description2: string;
    image: string;
    cartImage: string;
    petCategory: string;
    price: number;
    adopted: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export const PETS: Pet[] = [
    {
        id: 'husky-puppy-anita', name: 'Anita', image: 'assets/images/pets/anita.jpeg', cartImage: 'assets/img/cartImage/lucy.jpeg',
        gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
        petCategory: "husky",
        adopted: false,
        description1: "",
    } as Pet,

    {
        id: 'husky-puppy-hunter', name: 'Hunter', image: 'assets/images/pets/hunter.jpeg', cartImage: 'assets/img/cartImage/eva.jpeg',
        gender: 'Male', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
        petCategory: "husky",
        adopted: false,
        description1: "",
    } as Pet,

    {
        id: 'husky-puppy-hykah', name: 'Hykah', image: 'assets/images/pets/hykah.jpeg', cartImage: 'assets/img/cartImage/daisy.jpeg',
        gender: 'Male', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
        petCategory: "husky",
        adopted: true,
        description1: "",
    } as Pet,

    {
        id: 'husky-puppy-lj', name: 'Lj', image: 'assets/images/pets/lj.jpeg', cartImage: 'assets/img/cartImage/hercules.jpeg',
        gender: 'Male', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
        petCategory: "husky",
        adopted: false,
        description1: "",
    } as Pet,

    {
        id: 'husky-puppy-lucky', name: 'Lucky', image: 'assets/images/pets/lucky.jpeg', cartImage: 'assets/img/cartImage/peanut.jpeg',
        gender: 'Male', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
        petCategory: "husky",
        adopted: true,
        description1: "",
    } as Pet,

    {
        id: 'husky-puppy-mira', name: 'Mira', image: 'assets/images/pets/mira.jpeg', cartImage: 'assets/img/cartImage/toby.jpeg',
        gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
        petCategory: "husky",
        adopted: false,
        description1: "",
    } as Pet,

    {
        id: 'husky-puppy-miranda', name: 'Miranda', image: 'assets/images/pets/miranda.jpeg', cartImage: 'assets/img/cartImage/willow.jpeg',
        gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
        petCategory: "husky",
        adopted: false,
        description1: "",
    } as Pet,

    {
        id: 'husky-puppy-rosaline', name: 'Rosaline', image: 'assets/images/pets/rosaline.jpeg', cartImage: 'assets/img/cartImage/xena.jpeg',
        gender: 'Female', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
        petCategory: "husky",
        adopted: false,
        description1: "",
    } as Pet,
    {
        id: 'husky-puppy-terrell', name: 'Terrell', image: 'assets/images/pets/terrell.jpeg', cartImage: 'assets/img/cartImage/Leo.jpeg',
        gender: 'Male', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
        petCategory: "husky",
        adopted: false,
        description1: "",
    } as Pet,
    // {
    //     id: 'husky-puppy-leyla', name: 'Leyla', image: 'assets/images/pets/leyla.jpeg', cartImage: 'assets/img/cartImage/moana.jpeg',
    //     gender: 'Female', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
    //     petCategory: "husky",
    //     adopted: false,
    //     description1: "",
    // } as Pet,

    // {
    //     id: 'husky-puppy-maggie', name: 'Maggie', image: 'assets/images/pets/maggie.jpeg', cartImage: 'assets/img/cartImage/rusty.jpeg',
    //     gender: 'Female', age: "9 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 800,
    //     petCategory: "husky",
    //     adopted: true,
    //     description1: "",
    // } as Pet,

    // {
    //     id: 'husky-puppy-winnie', name: 'Winnie', image: 'assets/images/pets/winnie.jpeg',   cartImage: 'assets/img/cartImage/fiona.jpeg',
    //     gender: 'Female', age: "8 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 750,
    //     petCategory: "husky",
    //     adopted: false,
    //     description1: "",
    // } as Pet,

    // {
    //     id: 'husky-puppy-fanny', name: 'Fanny', image: 'assets/images/pets/fanny-1.jpeg',   cartImage: 'assets/img/cartImage/fanny-1.jpeg',
    //     gender: 'Female', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 1850,
    //     petCategory: "husky",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Healthy Huskies  community.",
    // } as Pet,
    // {
    //     id: 'husky-puppy-henry', name: 'Henry', image: 'assets/images/pets/henry.jpeg',   cartImage: 'assets/img/cartImage/henry.jpeg',
    //     gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2000,
    //     petCategory: "husky",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Healthy Huskies  community.",
    // } as Pet,

    // {
    //     id: 'husky-puppy-isabella', name: 'Isabella', image: 'assets/images/pets/isabella.jpeg',   cartImage: 'assets/img/cartImage/isabella.jpeg',
    //     gender: 'Female', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2000,
    //     petCategory: "husky",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Healthy Huskies  community.",
    // } as Pet,
    // {
    //     id: 'husky-puppy-clyde', name: 'Clyde', image: 'assets/images/pets/clyde.jpeg',   cartImage: 'assets/img/cartImage/clyde.jpeg',
    //     gender: 'Male', age: "10 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 700,
    //     petCategory: "husky",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Healthy Huskies  community.",
    // } as Pet,
    // {
    //     id: 'husky-puppy-buck', name: 'Buck', image: 'assets/images/pets/buck.jpeg',   cartImage: 'assets/img/cartImage/buck.jpeg',
    //     gender: 'Male', age: "11 Weeks", neutered: 'No', vaccinated: 'Yes', size: 'Small', price: 2200,
    //     petCategory: "husky",
    // adopted: false,
    //     description1: "These are gorgeous energetic bulldog pets. Short, dewormed and vet checked.These pets are ready for a new and ever home, we offer at a good rate , The best can only be found at Healthy Huskies  community.",
    // } as Pet,
];
