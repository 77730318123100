import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  constructor() { }

  html = `<h3 align="left"><span data-text-color="primary">Covid-19 Update</span></h3>
  <p>Due to the current Covid-19 pandemic, shipping our pets might take a little longer than before depending on your location. Due to guidelines prescibed by the US government, we are limiting the number of pet pickups per day so as to limit the number of people we come in contact with. Thanks </p>
  <hr/>
  <h3 align="left"><span data-text-color="primary">OVERVIEW</span></h3>
  <p>Our experienced staff brings years of knowledge in the puppy shipping industry and will make your shipping experience as easy as possible. Our Husky Puppies are shipped with most major airlines; they all have pressurized cabins with climate control systems, the puppies are flown in a special compartment for pets. We strive to provide you with extraordinary customer service from beginning to end. We explain every detail thoroughly providing full flight and tracking details so you can stay connected to your puppy(s) throughout the process.</p>
  <hr />
  <h3 align="left"><span data-text-color="primary">HOW DO WE SHIP OUR PUPPIES?</span></h3>
  <p>Our Vet does a complete health exam on your puppy which includes a fecal test to check if the dog is free from any worms or parasites. (We only ship our puppies on airlines that implement a Pet Safety Program) or We ship using Well Specialized Animal Courier .</p>
  <p>We will book a delivery appointment (flight) as soon as we receive the reservation deposit and send you an email itinerary of all of the times, flights, schedules, tracking AWB &amp; pick up details.</p>
  <p>The day of your puppy&rsquo;s journey, the delivery agency will pick him up from our breeding facility and prepare him for delivery before transporting him to the airport. Once all of the paperwork is filled out and your puppy is checked in at the airport, you will receive an email and phone call from the delivery agency with full flight details. During the day of the flight, we will continually update you step-by-step on the progress of the flight until your puppy arrives safely at your door step.</p>
  <p>You can also use the tracking number which we will send to you after the flight confirmation to track the progress of your puppy&rsquo;s flight from the website of the delivery agency.<br />Our puppies normally ship out early in the morning and they are delivered later on that same day or the next day in the morning if we put him on an early flight.</p>
  <p>Note: When you purchase your puppy through our website the first thing that you need to do is to take the dog to a licensed vet within the first 7 days to make sure he is in good health condition else our refunds policy will not apply.</p>
  <hr />
  <h3 align="left"><span data-text-color="primary">HOW LONG WILL IT BE BEFORE I RECEIVE PUPPY?</span></h3>
  <p>Given the puppy is at least 8 weeks of age or older and has the right medical documents required for shipment, we normally deliver within 6-8 hours the same day or the day after.<br />Note: We require at least 2 hours to purchase the airline ticket. That is the only way to get good air fares, so please be patient and we will do the best we can to get your puppy to you as fast as we can.</p>
  <p>How we deliver our Husky Puppies in the summer?</p>
  <p>Delivery and Shipping will be carried out by a professional animal relocation agency and the puppy will be shipped mostly by land with temperature regulatory crates ..</p>
  <hr />
  <h3 align="left"><span data-text-color="primary">MORE DETAILS</span></h3>
  <p>Your puppy will arrive in a brand new airline approved kennel with absorbent bedding and a food &amp; water dish. All puppies are flown in the pet conducive part of the aircraft which is a safe temperature controlled environment.<br />Note: Any kennelling fees owed because of flight cancellations will be the responsibility of the individual who receives the puppy.<br />Every puppy shipped through Sadle Ranch Huskies  will be handled with the utmost professional precision by one of our shipping experts.</p>
  <p><strong>For more inquiries contact us at saddleranchhuskyhome@gmail.com</strong></p>
  <p><span data-text-color="primary"><strong>THANK YOU! for choosing Sadle Ranch Huskies </strong></span></p>`
  ngOnInit() {
  }

}
